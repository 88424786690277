import { z } from 'zod'
import type { ContentCollection } from '~~/types'

export const pageSchema = z.object({
  // append the default schema https://content.nuxt.com/docs/collections/types#schema-overrides
  image: z.string().optional(),
  sitemap: z
    .object({
      loc: z.string().optional(),
      lastmod: z.string().optional(),
    })
    .optional(),
})

export type ContentPageSchema = z.infer<typeof pageSchema>

export const pageCollectionRootName = 'content_page'

export const contentCollections: ContentCollection[] = [
  // all pages
  {
    name: `${pageCollectionRootName}_all_en`,
    type: 'page',
    locale: 'en',
    urlPrefix: '',
    source: {
      include: 'en/**/*.md',
      prefix: '', // .path prefix (only applies to 'page' type),  needed to remove the locale prefix
    },
    schema: pageSchema,
  },
  {
    name: `${pageCollectionRootName}_all_fr`,
    type: 'page',
    locale: 'fr',
    urlPrefix: '/fr',
    source: {
      include: 'fr/**/*.md',
      prefix: '', // .path prefix (only applies to 'page' type),  needed to remove the locale prefix
    },
    schema: pageSchema,
  },

  // help pages
  {
    name: `${pageCollectionRootName}_help_en`,
    type: 'page',
    locale: 'en',
    urlPrefix: '/help',
    source: {
      include: 'en/1.help/**/*.md',
      prefix: '', // .path prefix (only applies to 'page' type),  needed to remove the locale prefix
    },
    schema: pageSchema,
  },
  {
    name: `${pageCollectionRootName}_help_fr`,
    type: 'page',
    locale: 'fr',
    urlPrefix: '/fr/help',
    source: {
      include: 'fr/1.help/**/*.md',
      prefix: '', // .path prefix (only applies to 'page' type),  needed to remove the locale prefix
    },
    schema: pageSchema,
  },

  // blog pages
  {
    name: `${pageCollectionRootName}_blog_en`,
    type: 'page',
    locale: 'en',
    urlPrefix: '/blog',
    source: {
      include: 'en/2.blog/**/*.md',
      prefix: '', // .path prefix (only applies to 'page' type),  needed to remove the locale prefix
    },
    schema: pageSchema,
  },
  {
    name: `${pageCollectionRootName}_blog_fr`,
    type: 'page',
    locale: 'fr',
    urlPrefix: '/fr/blog',
    source: {
      include: 'fr/2.blog/**/*.md',
      prefix: '', // .path prefix (only applies to 'page' type),  needed to remove the locale prefix
    },
    schema: pageSchema,
  },
]
