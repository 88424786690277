export const checksums = {
  "content_page_all_en": "v3.3.0--JxJiV-K3nuntvFlj2maml1XrqyydndVkzRuMdJjYhSQ",
  "content_page_all_fr": "v3.3.0--hR44RHX1nrAydozYQpRVurXrRuVPq9XYr2fqqiRWVwA",
  "content_page_help_en": "v3.3.0--I-pzK10dvUJy0zUxc51WrQ_7vgB07FTaC3RrAoKa_44",
  "content_page_help_fr": "v3.3.0--CIzNDm7XWMc0LCGHuXH0pF6J6x1h-ZXsSXqEAY7wAE0",
  "content_page_blog_en": "v3.3.0--UmzFQwMZb1yQGf5fh1M34d_-F1WxZ4jqxkF4rmYUIpo",
  "content_page_blog_fr": "v3.3.0--e0ZyEnA-CGMY-nt-seuUTm9OoSmXJcs9zqlgnGxRZoY"
}
export const checksumsStructure = {
  "content_page_all_en": "ZrE8byXDzCkfJ1lt9H9GM0VgFhXQZLqkauzkHvZlhl8",
  "content_page_all_fr": "Pc2m4SUdBt2VIR7S1lq_gRcMdr7w-X6FsyeOtuQ8Nlg",
  "content_page_help_en": "C85GRnESgyDYVrEfHRYsoXNl3q8jAbPpxdhy_1p4osY",
  "content_page_help_fr": "dVhG9xunJgoZohtyA9XjzGtIfXmgiwJtTdmEe0-ibJo",
  "content_page_blog_en": "VxVclA0SaZZn0LY0HQ0sgORjZ54TYtMQ-kvzCBYNmno",
  "content_page_blog_fr": "xF8-aJtgKDDtoXB2IUKhGp8INQhFa5HeeWLw1eEevX8"
}

export const tables = {
  "content_page_all_en": "_content_content_page_all_en",
  "content_page_all_fr": "_content_content_page_all_fr",
  "content_page_help_en": "_content_content_page_help_en",
  "content_page_help_fr": "_content_content_page_help_fr",
  "content_page_blog_en": "_content_content_page_blog_en",
  "content_page_blog_fr": "_content_content_page_blog_fr",
  "info": "_content_info"
}

export default {
  "content_page_all_en": {
    "type": "page",
    "fields": {
      "id": "string",
      "stem": "string",
      "extension": "string",
      "meta": "json",
      "path": "string",
      "title": "string",
      "description": "string",
      "seo": "json",
      "body": "json",
      "navigation": "json",
      "image": "string",
      "sitemap": "json"
    }
  },
  "content_page_all_fr": {
    "type": "page",
    "fields": {
      "id": "string",
      "stem": "string",
      "extension": "string",
      "meta": "json",
      "path": "string",
      "title": "string",
      "description": "string",
      "seo": "json",
      "body": "json",
      "navigation": "json",
      "image": "string",
      "sitemap": "json"
    }
  },
  "content_page_help_en": {
    "type": "page",
    "fields": {
      "id": "string",
      "stem": "string",
      "extension": "string",
      "meta": "json",
      "path": "string",
      "title": "string",
      "description": "string",
      "seo": "json",
      "body": "json",
      "navigation": "json",
      "image": "string",
      "sitemap": "json"
    }
  },
  "content_page_help_fr": {
    "type": "page",
    "fields": {
      "id": "string",
      "stem": "string",
      "extension": "string",
      "meta": "json",
      "path": "string",
      "title": "string",
      "description": "string",
      "seo": "json",
      "body": "json",
      "navigation": "json",
      "image": "string",
      "sitemap": "json"
    }
  },
  "content_page_blog_en": {
    "type": "page",
    "fields": {
      "id": "string",
      "stem": "string",
      "extension": "string",
      "meta": "json",
      "path": "string",
      "title": "string",
      "description": "string",
      "seo": "json",
      "body": "json",
      "navigation": "json",
      "image": "string",
      "sitemap": "json"
    }
  },
  "content_page_blog_fr": {
    "type": "page",
    "fields": {
      "id": "string",
      "stem": "string",
      "extension": "string",
      "meta": "json",
      "path": "string",
      "title": "string",
      "description": "string",
      "seo": "json",
      "body": "json",
      "navigation": "json",
      "image": "string",
      "sitemap": "json"
    }
  },
  "info": {
    "type": "data",
    "fields": {}
  }
}