import type { ContentCollection } from '~~/types'
import { pageCollectionRootName, contentCollections } from '~~/content.config.dvn'

export function getContentCollection(key: string = 'all', locale: string): ContentCollection {
  function findCollectionByName(name: string): ContentCollection {
    const collection = contentCollections.find((c) => c.name === name)
    if (!collection) {
      throw new Error(`Collection ${name} not found`)
    }
    return collection
  }
  return findCollectionByName(`${pageCollectionRootName}_${key}_${locale}`)
}
